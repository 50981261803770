import { playnityConfig } from 'config';
import { getContract, isInVestingPool } from 'helpers';
import { VestingPoolConfig, VestingPoolData } from 'models';
import { Dispatch } from 'react';
import { vestingActions } from 'store';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

import { Action } from '@reduxjs/toolkit';

export const getVestingPoolsData = (
  web3: Web3,
  networkId: number,
  connectedAddress: string
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      let vestingPoolsData: VestingPoolData[] = playnityConfig.vesting.pools.map(
        (vestingPool: VestingPoolConfig) => {
          const vestingPoolContractInstance: Contract = getContract(
            web3,
            vestingPool.vestingContractJSON,
            networkId
          );

          return {
            poolType: vestingPool.poolType,
            vestingPoolContract: vestingPoolContractInstance,
          };
        }
      );

      const asyncFilter = async (arr: any[]) =>
        arr.reduce(
          async (acc: VestingPoolData[], vestingPoolData: VestingPoolData) =>
            (await isInVestingPool(
              vestingPoolData.vestingPoolContract,
              connectedAddress
            ))
              ? [...(await acc), vestingPoolData]
              : acc,
          []
        );

      vestingPoolsData = await asyncFilter(vestingPoolsData);

      dispatch(vestingActions.setVestingPoolsData(vestingPoolsData));
    } catch (e) {
      console.log(e);
    }
  };
};
