import { SectionDetails } from 'components';
import React from 'react';

import StakingPools from './StakingPools';

export const Staking: React.FC = () => {
  const description =
    'Add tokens to our farm. Approve, deposit, withdraw and claim rewards. Select one of options presented below.';
  const title = 'Staking';

  return (
    <div className="staking">
      <SectionDetails description={description} title={title}></SectionDetails>
      <StakingPools />
    </div>
  );
};
