import { ButtonWithSpinner, Message, PercentageButtons } from 'components';
import { MessageText, MessageType, numbersOnly } from 'helpers';
import { StakingPoolItemData } from 'models';
import React from 'react';
import { Tab } from 'react-bootstrap';

interface StakingPoolItemStakeTabContentProps {
  loading: boolean;
  balance: string;
  stakingValue: string;
  staking: {
    stakingAllowance: string;
  };
  stakingPoolItemData: StakingPoolItemData;
  onApprove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onStake: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onStakeValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onApplyPercentage: (percentage: number) => void;
}

export const StakingPoolItemStakeTabContent: React.FC<StakingPoolItemStakeTabContentProps> = ({
  loading,
  balance,
  stakingValue,
  staking,
  stakingPoolItemData,
  onApprove,
  onStake,
  onStakeValueChange,
  onApplyPercentage,
}) => {
  console.log(
    'Rendering staking tab content, allowance: ',
    staking?.stakingAllowance,
    'Loading',
    loading
  );

  const stakeButtonDisabled = +stakingValue == 0;
  const button =
    +stakingValue > +staking.stakingAllowance ? (
      <ButtonWithSpinner
        text="Approve"
        classes="btn-style-two"
        onClick={onApprove}
        loading={loading}
      />
    ) : (
      <ButtonWithSpinner
        text="Stake"
        classes="btn-style-two"
        onClick={onStake}
        disabled={stakeButtonDisabled}
        loading={loading}
      />
    );

  return (
    <Tab.Pane eventKey="stake">
      <div className="tab-content">
        {+staking.stakingAllowance == 0 && (
          <div className="approve-section">
            <div>To stake tokens you need to approve them first</div>
            <ButtonWithSpinner
              text="Approve"
              classes="btn-style-two"
              onClick={onApprove}
              disabled={loading}
              loading={loading}
            />
          </div>
        )}
        {+staking.stakingAllowance != 0 && (
          <div className="stake-section">
            <div className="stake-section-description">
              {stakingPoolItemData.bondingPeriod ? (
                <span>
                  Staked tokens can be unstaked at any time, howewer a XX days
                  unbondping period will apply (i.e. unstaked tokens will be
                  transfered to your wallet 5 days after unstaking). Instant
                  withdrawal is also possible but will incur a 5% penalty fee.
                </span>
              ) : (
                <span>
                  Staked tokens will be locked for a period of XX months. By
                  staking in this pool you acknowledge that you will not be able
                  to withdraw the staked tokens by any means before this locking
                  period elapses.
                </span>
              )}
            </div>
            <div className="stake-section-actions">
              <div className="left">
                <div className="playnity-input-wrapper">
                  <input
                    className="playnity-input"
                    placeholder="0.00"
                    onChange={onStakeValueChange}
                    value={stakingValue}
                    onKeyPress={numbersOnly}
                  />
                </div>
                {button}
              </div>
              <div className="right">
                <PercentageButtons
                  applyPercentage={percentage => onApplyPercentage(percentage)}
                  disabled={loading}
                />
              </div>
            </div>
          </div>
        )}
        {+stakingValue > +staking.stakingAllowance ? (
          <Message
            messageType={MessageType.Error}
            descriptionText={MessageText.ValueGreaterThanApproved}
          />
        ) : (
          ''
        )}
      </div>
      {!+balance && +staking.stakingAllowance ? (
        <Message
          descriptionText={MessageText.NoTokens}
          messageType={MessageType.Error}
        />
      ) : (
        ''
      )}
    </Tab.Pane>
  );
};
