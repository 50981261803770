import { State } from 'store';
import { AppState } from 'store/reducers';
import Web3 from 'web3';

import { createSelector } from '@reduxjs/toolkit';

const selectApp = (state: State): AppState => state.app;

export const selectWeb3 = createSelector(
  [selectApp],
  (appState: AppState): Web3 => appState.web3
);

export const selectInitializingFinished = createSelector(
  [selectApp],
  (state: AppState): boolean => state.initializingFinished
);

export const selectConnectedAddress = createSelector(
  [selectApp],
  (state: AppState): string => state.connectedAddress
);

export const selectBalance = createSelector(
  [selectApp],
  (state: AppState): string => state.balance
);

export const selectLPBalance = createSelector(
  [selectApp],
  (state: AppState): string => state.balanceLP
);

export const selectMainError = createSelector(
  [selectApp],
  (state: AppState): boolean => state.mainError
);
