import { PermittedNetwork } from 'config';

export enum NoConnectionType {
  WrongNetwork = 'wrong-network',
  WalletNotConnected = 'wallet-not-connected',
}

export const isPermittedNetwork = (networkId: number) => {
  return Object.values(PermittedNetwork).some(
    (id: string) => +id === +networkId
  );
};
