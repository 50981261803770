import { Web3Units } from 'helpers';
import { TokenSymbol } from 'models';
import { Action, Dispatch } from 'redux';
import { appActions } from 'store';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

export const getBalance = (
  web3: Web3,
  tokenContract: Contract,
  symbol: TokenSymbol
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    const getData = async (web3: Web3, tokenContract: Contract) => {
      const accounts = await web3.eth.getAccounts();
      const balance: string = await tokenContract.methods
        .balanceOf(accounts[0])
        .call();

      return balance;
    };

    try {
      const balance = await getData(web3, tokenContract);
      const fromWeiBalance = web3.utils.fromWei(balance, Web3Units.Ether);

      symbol === TokenSymbol.PNY
        ? dispatch(appActions.setBalance(fromWeiBalance))
        : dispatch(appActions.setBalanceLP(fromWeiBalance));
    } catch (e) {
      console.log(e);
    }
  };
};
