import { DataItem } from 'components';
import { StakingPoolItemStakingData, TokenSymbol } from 'models';
import React from 'react';

interface StakingPoolItemDetailsProps {
  balance: string;
  staking: StakingPoolItemStakingData;
  symbol: TokenSymbol;
}

export const StakingPoolItemDetails: React.FC<StakingPoolItemDetailsProps> = ({
  balance,
  staking,
  symbol,
}) => {
  return (
    <>
      <div className="staking-pool-item-details">
        <DataItem label={`In wallet`} data={balance} symbol={symbol} />
        <DataItem label="Staked" data={staking.stakedTokens} symbol={symbol} />
        <DataItem
          label="Claimable rewards"
          data={staking.rewardTokens}
          symbol={symbol}
        />
      </div>
    </>
  );
};
