import React, { ReactElement } from 'react';

export const Preloader = (): ReactElement => {
  return (
    <div id="preloader">
      <div id="loading-center">
        <div id="loading-center-absolute">
          {/* <img src="img/icon/preloader.svg" alt="" /> */}
          <img src="playnity-head.png" alt="Playnity loader" />
        </div>
      </div>
    </div>
  );
};

export default Preloader;
