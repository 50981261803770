import React, { PropsWithChildren } from 'react';
import { Card } from 'react-bootstrap';

interface InfoCardProps {
  headerText: string;
  description: string;
}

export const InfoCard: React.FC<PropsWithChildren<InfoCardProps>> = ({
  headerText,
  description,
  children,
}) => {
  return (
    <Card body className="info-card">
      <div className="info-card-header">{headerText}</div>
      <div className="info-card-description">{description}</div>
      {children}
    </Card>
  );
};
