import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export const Home = (): ReactElement => {
  return (
    <div className="home">
      <div className="home-header">
        <div className="home-header-text bold">Welcome in</div>
        <div className="home-header-logo">
          <img style={{ width: '190px' }} src="/playnity-app.png" alt="Logo" />
        </div>
        <div className="home-header-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris
        </div>
      </div>

      <div className="home-section staking">
        <div className="home-section-info">
          <div className="home-section-info-header">
            <div>PLAYNITY</div>
            <div className="bold geco">STAKING</div>
          </div>
          <div className="home-section-info-content">
            <div className="home-section-info-content-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim
            </div>
          </div>
          <Link to="/staking" className="btn btn-style-two">
            Go to staking
          </Link>
        </div>
        <div className="home-section-image">
          <img src="img/slider/four_slider_img01.png" />
        </div>
      </div>

      <div className="home-section vesting">
        <div className="home-section-image">
          <img src="img/slider/four_slider_img02.png" />
        </div>
        <div className="home-section-info">
          <div className="home-section-info-header">
            <div>PLAYNITY</div>
            <div className="bold geco">VESTING</div>
          </div>
          <div className="home-section-info-content">
            <div className="home-section-info-content-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim
            </div>
          </div>
          <Link to="/vesting" className="btn btn-style-two">
            Go to vesting
          </Link>
        </div>
      </div>
    </div>
  );
};
