import {
  StakingPoolData,
  StakingPoolItemData,
  TokenData,
  VestingPoolData,
} from 'models';
import React from 'react';

interface DiagniosticProps {
  tokenData: TokenData;
  LPTokenData: TokenData;
  stakingPoolsData: StakingPoolData[];
  vestingPoolsData: VestingPoolData[];
  connectedAddress: string;
}

export const Diagnostic: React.FC<DiagniosticProps> = ({
  tokenData,
  LPTokenData,
  stakingPoolsData,
  vestingPoolsData,
  connectedAddress,
}) => {
  const vestingPools = vestingPoolsData?.map(
    (vestingPoolData: VestingPoolData, index: number) => (
      <div key={`diagnostic-vesting-pool-data-${index}`}>
        {vestingPoolData.poolType} -{' '}
        {vestingPoolData.vestingPoolContract.options.address}
      </div>
    )
  );
  const stakingPools = stakingPoolsData?.map(
    (stakingPoolData: StakingPoolData, stakingPoolDataIndex: number) => (
      <div key={`diagnostic-staking-pool-data-${stakingPoolDataIndex}`}>
        <div>{`> ${stakingPoolData.poolType}`}</div>
        {stakingPoolData.poolItems.map(
          (
            stakingPoolItemData: StakingPoolItemData,
            stakingPoolItemDataIndex: number
          ) => (
            <div
              className="pl-20"
              key={`diagnostic-staking-pool-item-data-${stakingPoolData.poolType
                .toLocaleLowerCase()
                .replace(' ', '-')}-${stakingPoolItemDataIndex}`}
            >
              {stakingPoolItemData.poolItemName}
              {stakingPoolItemData.poolItemtype} -
              {stakingPoolItemData.stakingPoolItemContract.options.address}
            </div>
          )
        )}
      </div>
    )
  );

  return (
    <div style={{ color: 'white' }}>
      Diagnostic data:
      <div className="mt-20">===Tokens Data===</div>
      <div>
        {tokenData.name} - {tokenData.tokenContract.options.address}
      </div>
      <div>
        {LPTokenData.name} - {LPTokenData.tokenContract.options.address}
      </div>
      <div className="mt-20">===Staking Pools=== </div>
      {stakingPools}
      <div className="mt-20">===Vesting Pools=== </div>
      {vestingPools?.length
        ? vestingPools
        : 'Unavailable for connected address'}
      <div className="mt-20">===Connected Address===</div>
      {connectedAddress}
    </div>
  );
};
