import { Footer, Header } from 'components';
import React, { PropsWithChildren } from 'react';

interface LayoutProps {
  connectWallet: () => void;
  disconnectWallet: () => void;
}

export const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({
  connectWallet,
  disconnectWallet,
  children,
}) => {
  return (
    <>
      <Header
        connectWallet={connectWallet}
        disconnectWallet={disconnectWallet}
      />

      <div className="app-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-9">{children}</div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
