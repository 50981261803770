import { InfoCard, Layout, Preloader } from 'components';
import Playnity from 'components/Playnity';
import { getWeb3, isPermittedNetwork } from 'helpers';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  appActions,
  getStakingPoolsData,
  getTokensContracts,
  getVestingPoolsData,
  selectConnectedAddress,
  selectInitializingFinished,
  selectMainError,
  selectTokenData,
  selectWeb3,
} from 'store';
import Web3 from 'web3';

// Route guards https://blog.netcetera.com/how-to-create-guarded-routes-for-your-react-app-d2fe7c7b6122
// https://redux-observable.js.org/docs/basics/Epics.html
const App: React.FC = () => {
  const dispatch = useDispatch();
  const web3 = useSelector(selectWeb3);
  const connectedAddress = useSelector(selectConnectedAddress);
  const initializingFinished = useSelector(selectInitializingFinished);
  const mainError = useSelector(selectMainError);
  const tokenData = useSelector(selectTokenData);

  useEffect(() => {
    connectWallet();
    addEventListeners();
    // return ()=> {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectWallet = async () => {
    console.log('Connecting wallet');
    try {
      // Get network provider and web3 instance.
      const web3: Web3 = await getWeb3();
      const networkId: number = await web3.eth.net.getId();
      const accounts = await web3.eth.getAccounts();

      dispatch(
        appActions.setInitialData({
          web3,
          connectedAddress: accounts[0],
          balance: null,
        })
      );

      if (isPermittedNetwork(networkId)) {
        dispatch(getTokensContracts(web3, networkId));
        dispatch(getStakingPoolsData(web3, networkId));
        dispatch(getVestingPoolsData(web3, networkId, accounts[0]));
      }

      dispatch(appActions.setInitializingFinished(true));
      dispatch(appActions.setMainError(false));
    } catch (error) {
      dispatch(appActions.setMainError(true));
    }
  };

  const disconnectWallet = async () => {
    // TODO
    // if (window.ethereum) {
    //   await window.ethereum.disconnect();
    // }
  };

  const addEventListeners = (): void => {
    addAccountChangedEventListener();
    addNetworkChangedEventListener();
  };

  const addAccountChangedEventListener = (): void => {
    window.ethereum?.on('accountsChanged', () => {
      console.log('Adding account changed event listener');
      window.location.reload();
    });
  };

  const addNetworkChangedEventListener = (): void => {
    window.ethereum?.on('chainChanged', () => {
      console.log('Adding network changed event listener');
      window.location.reload();
    });
  };

  const contractsAvailable = tokenData?.tokenContract?.options?.address;
  const isWalletConnected = web3 && connectedAddress;

  return (
    <Fragment>
      {!initializingFinished && !mainError && <Preloader />}
      <Layout connectWallet={connectWallet} disconnectWallet={disconnectWallet}>
        <>
          {isWalletConnected && initializingFinished && contractsAvailable && (
            <Playnity />
          )}
          {!isWalletConnected && (
            <InfoCard
              headerText="This is where the adventure begins...."
              description="I hope you’re ready to go my friend. Prepare your wallet and let your funds start the journey!"
            >
              <img
                className="info-card-image"
                src="img/slider/four_slider_img02.png"
              />
            </InfoCard>
          )}
          {isWalletConnected && !contractsAvailable && (
            <InfoCard
              headerText="Wrong network selected"
              description="Please change network to Binance Smart Chain to continue your journey"
            >
              <img
                className="info-card-image"
                src="img/slider/four_slider_img02.png"
              />
            </InfoCard>
          )}
        </>
      </Layout>
    </Fragment>
  );
};

export default App;
