import React from 'react';

interface SectionHeaderProps {
  description: string;
  title: string;
}

export const SectionDetails: React.FC<SectionHeaderProps> = ({
  description,
  title,
}) => {
  return (
    <div className="section-details">
      {/* TODO: Remove home-four-title class after fixing problem with public folder */}
      <h2 className="section-details-header">
        Playnity <span>{title}</span>
      </h2>

      <div className="section-details-description">{description}</div>
    </div>
  );
};
