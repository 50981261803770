import { Contract } from 'web3-eth-contract';

export enum PlaynityTokenName {
  Playnity = 'Playnity',
  LPPlaynity = 'LP-Playnity',
}

export enum TokenSymbol {
  PNY = 'PNY',
  'LPPNY' = 'Cake-LP',
}

export interface TokenData {
  name: PlaynityTokenName;
  symbol: TokenSymbol;
  decimals: number;
  tokenContract: Contract;
}

export interface TokenConfig {
  name: PlaynityTokenName;
  symbol: TokenSymbol;
  decimals: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tokenContractJSON: any;
}
