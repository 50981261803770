import { Diagnostic, Home, Staking, Vesting } from 'components';
import { StakingPoolData, TokenData, VestingPoolData } from 'models';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import {
  getBalance,
  selectConnectedAddress,
  selectLPTokenData,
  selectStakingPools,
  selectTokenData,
  selectVestingPools,
  selectWeb3,
} from 'store';
import Web3 from 'web3';

const Playnity: React.FC = () => {
  const dispatch = useDispatch();
  const web3: Web3 = useSelector(selectWeb3);
  const tokenData: TokenData = useSelector(selectTokenData);
  const LPTokenData: TokenData = useSelector(selectLPTokenData);
  const stakingPoolsData: StakingPoolData[] = useSelector(selectStakingPools);
  const vestingPoolsData: VestingPoolData[] = useSelector(selectVestingPools);
  const connectedAddress: string = useSelector(selectConnectedAddress);

  useEffect(() => {
    if (tokenData && web3) {
      dispatch(getBalance(web3, tokenData.tokenContract, tokenData.symbol));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenData]);

  useEffect(() => {
    if (LPTokenData && web3) {
      dispatch(getBalance(web3, LPTokenData.tokenContract, LPTokenData.symbol));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LPTokenData]);

  // TODO: Change after production deployment
  // eslint-disable-next-line no-constant-condition
  const developmentRoute = true ? (
    <Route
      path="/diagnostics"
      element={
        <Diagnostic
          tokenData={tokenData}
          LPTokenData={LPTokenData}
          stakingPoolsData={stakingPoolsData}
          vestingPoolsData={vestingPoolsData}
          connectedAddress={connectedAddress}
        />
      }
    />
  ) : (
    ''
  );

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/staking" element={<Staking />} />
      <Route path="/vesting" element={<Vesting />} />
      {developmentRoute}
    </Routes>
  );
};

export default Playnity;
