import { playnityConfig } from 'config';
import { getContract } from 'helpers';
import {
  StakingPoolConfig,
  StakingPoolData,
  StakingPoolItemConfig,
  StakingPoolItemData,
} from 'models';
import { Dispatch } from 'react';
import { stakingActions } from 'store';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

import { Action } from '@reduxjs/toolkit';

export const getStakingPoolsData = (
  web3: Web3,
  networkId: number
): ((dispatch: Dispatch<Action>) => void) => {
  return (dispatch: Dispatch<Action>): void => {
    try {
      const stakingPoolsData: StakingPoolData[] = playnityConfig.staking?.pools.map(
        (stakingPool: StakingPoolConfig) => {
          const stakingPoolItems: StakingPoolItemData[] = stakingPool.poolItems.map(
            (stakingPoolItem: StakingPoolItemConfig): StakingPoolItemData => {
              const stakingContractInstance: Contract = getContract(
                web3,
                stakingPoolItem.stakingContractJSON,
                networkId
              );

              return {
                poolItemName: stakingPoolItem.poolItemName,
                poolItemtype: stakingPoolItem.poolItemtype,
                stakingPoolItemContract: stakingContractInstance,
                unbondingFee: stakingPoolItem.unbondingFee,
                bondingPeriod: stakingPoolItem.bondingPeriod,
                lockPeriod: stakingPoolItem.lockPeriod,
              };
            }
          );

          return {
            poolType: stakingPool.poolType,
            poolItems: stakingPoolItems,
          };
        }
      );

      dispatch(stakingActions.setStakingPoolsData(stakingPoolsData));
    } catch (e) {
      console.log(e);
    }
  };
};
