import { InfoCard } from 'components';
import { VestingPoolData } from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectTokenData, selectVestingPools } from 'store';

import VestingPool from './VestingPool';

const VestingPools: React.FC = () => {
  const vestingPoolsData: VestingPoolData[] = useSelector(selectVestingPools);
  const tokenData = useSelector(selectTokenData);
  const vestingPools = vestingPoolsData?.map(
    (vestingPoolData: VestingPoolData, index: number) => {
      return (
        <VestingPool
          key={`vesting-pool-compoonent-${index}`}
          tokenData={tokenData}
          poolType={vestingPoolData.poolType}
          vestingContract={vestingPoolData.vestingPoolContract}
        />
      );
    }
  );

  return vestingPools?.length ? (
    <div className="vesting-pools">{vestingPools}</div>
  ) : (
    <InfoCard
      headerText="Vesting unavailable"
      description="This section is not applicable to your wallet address"
    />
  );
};

export default VestingPools;
