import { Contract } from 'web3-eth-contract';

export enum VestingPoolType {
  Team = 'Team',
  Seed = 'Seed',
  Private = 'Private',
  Public = 'Public',
}

export const isInVestingPool = async (
  contract: Contract,
  connectedAddress: string
) => {
  try {
    const amountAllocated = await contract.methods
      .amountAllocated(connectedAddress)
      .call();

    return +amountAllocated;
  } catch (e) {
    console.log(e);

    return false;
  }
};
