import { playnityConfig } from 'config';
import { getContract } from 'helpers';
import { TokenConfig, TokenData } from 'models';
import { Dispatch } from 'react';
import { tokenActions } from 'store';
import Web3 from 'web3';

import { Action } from '@reduxjs/toolkit';

export const getTokensContracts = (
  web3: Web3,
  networkId: number
): ((dispatch: Dispatch<Action>) => void) => {
  return (dispatch: Dispatch<Action>) => {
    try {
      const tokenContracts: TokenData[] = playnityConfig.tokens.map(
        (config: TokenConfig) => {
          const tokenContract = getContract(
            web3,
            config.tokenContractJSON,
            networkId
          );

          return {
            tokenContract,
            name: config.name,
            symbol: config.symbol,
            decimals: config.decimals,
          };
        }
      );

      dispatch(tokenActions.setTokensData(tokenContracts));
    } catch (e) {
      console.log(e);
    }
  };
};
