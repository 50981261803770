/* eslint-disable @typescript-eslint/no-explicit-any */
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';

export const getContract = (
  web3: Web3,
  contractJSON: Record<string, unknown>,
  networkId: number
): Contract => {
  return new web3.eth.Contract(
    contractJSON.abi as AbiItem[],
    (contractJSON.networks as any)[networkId]?.address
  );
};
