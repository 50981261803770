export const TOKEN_SYMBOL = 'PNY';

// export const maskWalletAddressRegexp = new RegExp(
//   /(?<=.{6}).*((?=.{4})(?!.{5}))/
// );

export const applyMask = (text: string): string => {
  return text.substr(0, 6) + '**' + text.substr(-4);
};

export const roundNumber = (value: string): string => {
  const pointIndex = value.lastIndexOf('.');

  return pointIndex > 0 ? value.slice(0, pointIndex + 4) : value + '.000';
};
