import { ButtonWithSpinner, DataItem, Message } from 'components';
import dayjs from 'dayjs';
import { DateFormat, roundNumber } from 'helpers';
import {
  StakingPoolItemBondedTokensData,
  StakingPoolItemData,
  StakingPoolItemStakingData,
  TokenSymbol,
} from 'models';
import React from 'react';
import { Card } from 'react-bootstrap';

interface BondedTokensProps {
  loading: boolean;
  staking: StakingPoolItemStakingData;
  bondedTokensData: StakingPoolItemBondedTokensData[];
  tokenSymbol: TokenSymbol;
  stakingPoolItemData: StakingPoolItemData;
  onWithdraw: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    instant?: boolean
  ) => void;
}

export const BondedTokens: React.FC<BondedTokensProps> = ({
  loading,
  staking,
  bondedTokensData,
  tokenSymbol,
  stakingPoolItemData,
  onWithdraw,
}) => {
  // TODO: Refresh bonding table
  // const [time, setTime] = useState(Date.now());

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log(dayjs().unix());
  //     setTime(dayjs().unix());
  //   }, 1000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const unbondingTableItems = bondedTokensData.map(
    (unbondingTableItem: StakingPoolItemBondedTokensData, index: number) => {
      const isAvailableForWithdrawal =
        +unbondingTableItem.timestamp <= dayjs().unix();

      return (
        <div
          className={`bonded-tokens-table-item ${
            isAvailableForWithdrawal ? 'ready' : ''
          }`}
          key={`bonded-tokens-table-item-${index}`}
        >
          <div className="time">
            {isAvailableForWithdrawal
              ? 'Available for withdrawal'
              : dayjs(+unbondingTableItem.timestamp * 1000).format(
                  DateFormat.DateWithTime
                )}
          </div>
          <div className="amount">
            {roundNumber(unbondingTableItem.amount)} {tokenSymbol}
          </div>
        </div>
      );
    }
  );

  return (
    <Card body className="bonded-tokens">
      <div className="bonded-tokens-header">
        <img
          className="bonded-tokens-image"
          src="https://via.placeholder.com/75x75"
        />
        <div className="bonded-tokens-message">
          <Message headerText="Bonding details">
            <DataItem
              label="Bonded tokens"
              data={staking.totalInUnbonding}
              symbol={tokenSymbol}
              classes="horizontal justify-between"
            />
            <DataItem
              label="Available for withdrawal"
              data={staking.availableForWithdrawal}
              symbol={tokenSymbol}
              classes="horizontal justify-between mt-8"
            />
          </Message>
        </div>
      </div>
      <div className="bonded-tokens-table">
        <div className="bonded-tokens-table-header">
          <div className="time">
            Bonded till<span className="time-amount-separator"> / </span>
          </div>
          <div className="amount">Bonded amount</div>
        </div>
        {unbondingTableItems}
        <div className="bonded-tokens-buttons">
          <div className="playnity-btn-wrapper aquamarine">
            <ButtonWithSpinner
              classes="playnity-btn-outline btn-no-fee"
              onClick={onWithdraw}
              disabled={!+staking.availableForWithdrawal}
              loading={loading}
            >
              <div>
                Withdraw <div className="fee-text">no fee</div>
              </div>{' '}
            </ButtonWithSpinner>
          </div>

          <ButtonWithSpinner
            classes="btn-style-three btn-fee"
            onClick={event => onWithdraw(event, true)}
            disabled={!+staking.totalInUnbonding}
            loading={loading}
          >
            <div>
              Instant Withdraw{' '}
              <div className="fee-text">
                with {stakingPoolItemData.unbondingFee} fee{' '}
              </div>
            </div>
          </ButtonWithSpinner>
        </div>
      </div>
    </Card>
  );
};
