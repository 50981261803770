import {
  BondedTokens,
  ButtonWithSpinner,
  Message,
  PercentageButtons,
} from 'components';
import { MessageText, MessageType, numbersOnly } from 'helpers';
import {
  StakingPoolItemData,
  StakingPoolItemStakingData,
  TokenData,
} from 'models';
import React from 'react';
import { Tab } from 'react-bootstrap';

interface StakingPoolItemWithdrawTabContentProps {
  loading: boolean;
  bondingPeriod: number;
  lockPeriod: number;
  unstakingValue: string;
  staking: StakingPoolItemStakingData;
  tokenData: TokenData;
  stakingPoolItemData: StakingPoolItemData;
  onUnstake: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onWithdraw: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    instant?: boolean
  ) => void;
  onUnstakeValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onApplyPercentage: (percentage: number) => void;
}

export const StakingPoolItemWithdrawTabContent: React.FC<StakingPoolItemWithdrawTabContentProps> = ({
  loading,
  bondingPeriod,
  lockPeriod,
  unstakingValue,
  staking,
  tokenData,
  stakingPoolItemData,
  onUnstake,
  onWithdraw,
  onUnstakeValueChange,
  onApplyPercentage,
}) => {
  const unstakeButtonDisabled =
    +staking.stakedTokens == 0 ||
    +unstakingValue == 0 ||
    +unstakingValue > +staking.stakedTokens;
  const withdrawButtonDisabled =
    +staking.availableForWithdrawal == 0 ||
    +unstakingValue == 0 ||
    +unstakingValue > +staking.availableForWithdrawal;

  console.log('TotalInUnbonding', staking.totalInUnbonding);
  console.log('AvailableForWithdrawal', staking.availableForWithdrawal);

  return (
    <Tab.Pane eventKey="withdraw" unmountOnExit={true} mountOnEnter={true}>
      <div className="tab-content">
        <div className="unstake-section">
          <div className="unstake-section-description">
            {stakingPoolItemData.lockPeriod && (
              <span>
                You can withdraw only the amount for which the lock period of XX
                has expired (Available for withdrawal)
              </span>
            )}
            {stakingPoolItemData.bondingPeriod &&
              !(
                +staking.totalInUnbonding + +staking.availableForWithdrawal
              ) && (
                <span>
                  You don&apos;t have any tokens available for withdrawal.
                  Please unstake your tokens first. They will be available for
                  you after unbonding period or instantly, if you pay an instant
                  withdrawal fee
                </span>
              )}
          </div>
          <div className="unstake-section-actions">
            <div className="left">
              <div className="playnity-input-wrapper">
                <input
                  className="playnity-input"
                  placeholder="0.00"
                  onChange={onUnstakeValueChange}
                  value={unstakingValue}
                  onKeyPress={numbersOnly}
                />
              </div>
              {bondingPeriod && (
                <ButtonWithSpinner
                  text="Unstake"
                  classes="btn btn-style-two"
                  onClick={onUnstake}
                  disabled={unstakeButtonDisabled}
                  loading={loading}
                />
              )}
              {lockPeriod && (
                <ButtonWithSpinner
                  text="Withdraw"
                  classes="btn btn-style-three"
                  onClick={onWithdraw}
                  disabled={withdrawButtonDisabled}
                  loading={loading}
                />
              )}
            </div>
            <div className="right">
              <PercentageButtons
                disabled={loading}
                variant={lockPeriod ? 'aquamarine' : ''}
                applyPercentage={percentage => onApplyPercentage(percentage)}
              />
            </div>
          </div>
        </div>
        {+unstakingValue > +staking.stakedTokens && (
          <Message
            messageType={MessageType.Error}
            descriptionText={MessageText.UnstakingValueNotGreaterThanStaked}
          />
        )}
        {!!staking?.bondedTokensData?.length && (
          <BondedTokens
            loading={loading}
            staking={staking}
            bondedTokensData={staking.bondedTokensData}
            tokenSymbol={tokenData.symbol}
            stakingPoolItemData={stakingPoolItemData}
            onWithdraw={(event, instant?) => onWithdraw(event, instant)}
          />
        )}
      </div>
    </Tab.Pane>
  );
};
