export enum MessageType {
  Error = 'error',
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum MessageText {
  ValueGreaterThanApproved = 'Entered value is greated than approved value. Please approve first',
  UnstakingValueNotGreaterThanStaked = 'Unstaking value should not be greater than staked tokens',
  NoTokens = 'You do not have any tokens.',
  NothingToClaim = "You don't have anything to claim yet. Please come back later",
  EverythingClaimed = 'You have claimed everything from this pool',
}
