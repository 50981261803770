import { DataItem, StakingPoolItem } from 'components';
import {
  StakingPoolData,
  StakingPoolItemData,
  TokenData,
  TokenSymbol,
} from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBalance, selectLPBalance } from 'store';

interface StakingPoolProps {
  tokenData: TokenData;
  stakingPoolData: StakingPoolData;
}

const StakingPool: React.FC<StakingPoolProps> = ({
  tokenData,
  stakingPoolData,
}) => {
  const balance: string = useSelector(selectBalance);
  const LPBalance: string = useSelector(selectLPBalance);
  const stakingPoolClassName = `staking-pool ${stakingPoolData.poolType
    .toLocaleLowerCase()
    .replaceAll(' ', '-')}`;
  const stakingPoolItems = stakingPoolData.poolItems?.map(
    (stakingPoolItemData: StakingPoolItemData, index: number) => (
      <StakingPoolItem
        key={`staking-pool-item-compoonent-${index}`}
        stakingPoolItemData={stakingPoolItemData}
        tokenData={tokenData}
      />
    )
  );

  return (
    <div className={stakingPoolClassName}>
      <div className="staking-pool-header">
        <div className="staking-pool-header-type">
          {'//'} {stakingPoolData.poolType}
          <span className="symbol">{tokenData.symbol}</span>
        </div>
        <div className="staking-pool-header-details">
          <div className="staking-pool-header-amount">
            <DataItem
              label="Available tokens:"
              data={tokenData.symbol === TokenSymbol.PNY ? balance : LPBalance}
              symbol={tokenData.symbol}
              classes="horizontal"
            />
          </div>
          <div>
            <a
              className="staking-pool-header-link"
              // TODO: Adjust links
              href={
                tokenData.symbol === TokenSymbol.PNY
                  ? 'https://www.pancakeswap.finance'
                  : 'https://www.pancakeswap.finance'
              }
              target="_blank"
              rel="noreferrer noopener"
            >
              Get {tokenData.symbol} Tokens
              <i
                className="fa fa-external-link-alt staking-pool-header-link-icon"
                aria-hidden="true"
              ></i>
            </a>
          </div>
        </div>
      </div>
      {stakingPoolItems}
    </div>
  );
};

export default StakingPool;
