import { applyMask } from 'helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink as Link } from 'react-router-dom';
import { State } from 'store';

interface HeaderProps {
  connectWallet: () => void;
  disconnectWallet: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  connectWallet,
  disconnectWallet,
}) => {
  const connectedAddress = useSelector(
    (state: State) => state.app.connectedAddress
  );

  return (
    <header className="header header-style-four">
      <div id="sticky-header" className="header-four-wrap sticky-menu">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="main-menu menu-style-two">
                <nav>
                  <div className="logo">
                    {/* TODO: Remove styles */}
                    <img
                      style={{ width: '190px' }}
                      src="/playnity-app.png"
                      alt="Logo"
                    />
                  </div>
                  <div
                    id="mobile-menu"
                    className="navbar-wrap d-none d-lg-flex"
                  >
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/staking">Staking</Link>
                      </li>
                      <li>
                        <Link to="/vesting">Vesting</Link>
                      </li>
                      <li>
                        <Link to="/diagnostics">Diagnostics</Link>
                      </li>
                      <li className="header-wallet-section">
                        {connectedAddress && (
                          <div
                            className="wallet-address"
                            onClick={disconnectWallet}
                          >
                            <i className="fas fa-wallet"></i>
                            <span>{applyMask(connectedAddress)}</span>
                          </div>
                        )}
                        {!connectedAddress && (
                          <div
                            className="wallet-address"
                            onClick={connectWallet}
                          >
                            Connect wallet
                          </div>
                        )}
                      </li>
                      {/* <li className="header-search">
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#search-modal"
                        >
                          <i className="fas fa-search"></i>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  {/* <div className="header-action">
                    <ul>
                      <li className="header-search">
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#search-modal"
                        >
                          <i className="fas fa-search"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </nav>
              </div>
              <div className="mobile-menu"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
