import { isDevelopment } from 'helpers';

import { combineReducers, configureStore } from '@reduxjs/toolkit';

import {
  appSlice,
  AppState,
  stakingSlice,
  StakingState,
  tokenSlice,
  TokenState,
  vestingSlice,
  VestingState,
} from './reducers';

export interface State {
  app: AppState;
  token: TokenState;
  staking: StakingState;
  vesting: VestingState;
}

export const appActions = appSlice.actions;
export const stakingActions = stakingSlice.actions;
export const vestingActions = vestingSlice.actions;
export const tokenActions = tokenSlice.actions;

export const store = configureStore({
  reducer: combineReducers({
    app: appSlice.reducer,
    staking: stakingSlice.reducer,
    vesting: vestingSlice.reducer,
    token: tokenSlice.reducer,
  }),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }),
  // TODO: Causes errors in console
  devTools: isDevelopment(),
});

export * from './actions';
export * from './selectors';
export * from './reducers';
