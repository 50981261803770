import { StakingPoolData } from 'models';
import { State } from 'store';
import { StakingState } from 'store/reducers';

import { createSelector } from '@reduxjs/toolkit';

const selectStakingState = (state: State): StakingState => state.staking;

export const selectStakingPools = createSelector(
  [selectStakingState],
  (stakingState: StakingState): StakingPoolData[] => stakingState.pools
);
