import { TokenData, TokenSymbol } from 'models';
import { State, TokenState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

const selectToken = (state: State): TokenState => state.token;

export const selectTokensData = createSelector(
  [selectToken],
  (state: TokenState): TokenData[] => state.tokensData
);

export const selectTokenData = createSelector(
  [selectToken],
  (state: TokenState): TokenData =>
    state.tokensData?.find(tokenData => tokenData.symbol === TokenSymbol.PNY)
);

export const selectLPTokenData = createSelector(
  [selectToken],
  (state: TokenState): TokenData =>
    state.tokensData?.find(tokenData => tokenData.symbol === TokenSymbol.LPPNY)
);
