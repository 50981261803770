import { ButtonWithSpinner } from 'components';
import { TokenData } from 'models';
import React from 'react';
import { Tab } from 'react-bootstrap';

interface StakingPoolItemClaimTabContentProps {
  loading: boolean;
  tokenData: TokenData;
  staking: {
    rewardTokens: string;
  };
  onClaim: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const StakingPoolItemClaimTabContent: React.FC<StakingPoolItemClaimTabContentProps> = ({
  loading,
  tokenData,
  staking,
  onClaim,
}) => {
  const canClaim = !!+staking.rewardTokens;

  return (
    <Tab.Pane eventKey="claim">
      <div className="tab-content">
        <div className="claim-section">
          <img
            className="claim-section-image"
            src="https://via.placeholder.com/75x75"
          />
          <div className="claim-section-info">
            {canClaim && (
              <div className="claim-section-info-header">Well Done!</div>
            )}
            <div className="claim-section-info-details">
              {canClaim && (
                <div>
                  <div className="text">You can claim your reward</div>
                  <span className="amount">
                    {staking.rewardTokens} {tokenData.symbol}
                  </span>
                </div>
              )}
              {!canClaim && (
                <span>
                  You do not have any rewards yet. Please come back later
                </span>
              )}
            </div>
          </div>
          <ButtonWithSpinner
            text="Claim"
            classes="btn btn-style-three claim-section-button"
            onClick={onClaim}
            disabled={!canClaim}
            loading={loading}
          />
        </div>
      </div>
    </Tab.Pane>
  );
};
