import React, { ReactElement } from 'react';

export const SliderArea = (): ReactElement => {
  return (
    <section className="slider-area home-four-slider">
      <div className="slider-active">
        <div className="single-slider slider-bg slider-style-two">
          <div className="container custom-container-two">
            <div className="row">
              <div className="col-xl-6 col-lg-7 col-md-11">
                <div className="slider-content">
                  <h6 data-animation="fadeInUp" data-delay=".4s">
                    world gaming
                  </h6>
                  <h2 data-animation="fadeInUp" data-delay=".4s">
                    Create <span>Manage</span> Matches
                  </h2>
                  <p data-animation="fadeInUp" data-delay=".6s">
                    Find technology or people for digital projects in public
                    sector and Find an individual specialist develope
                    researcher.
                  </p>
                  <a
                    href="#"
                    className="btn btn-style-two"
                    data-animation="fadeInUp"
                    data-delay=".8s"
                  >
                    READ MORE
                  </a>
                </div>
              </div>
            </div>
            <div className="slider-img">
              <img
                src="img/slider/four_slider_img01.png"
                alt=""
                data-animation="slideInRightS"
                data-delay=".8s"
              />
            </div>
          </div>
        </div>
        <div className="single-slider slider-bg slider-style-two">
          <div className="container custom-container-two">
            <div className="row">
              <div className="col-xl-6 col-lg-7 col-md-11">
                <div className="slider-content">
                  <h6 data-animation="fadeInUp" data-delay=".4s">
                    world gaming
                  </h6>
                  <h2 data-animation="fadeInUp" data-delay=".4s">
                    Create <span>Manage</span> Matches
                  </h2>
                  <p data-animation="fadeInUp" data-delay=".6s">
                    Find technology or people for digital projects in public
                    sector and Find an individual specialist develope
                    researcher.
                  </p>
                  <a
                    href="#"
                    className="btn btn-style-two"
                    data-animation="fadeInUp"
                    data-delay=".8s"
                  >
                    READ MORE
                  </a>
                </div>
              </div>
            </div>
            <div className="slider-img">
              <img
                src="img/slider/four_slider_img02.png"
                alt=""
                data-animation="slideInRightS"
                data-delay=".8s"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderArea;
