import BN from 'bn.js';
import {
  Message,
  StakingPoolItemClaimTabContent,
  StakingPoolItemStakeTabContent,
  StakingPoolItemWithdrawTabContent,
} from 'components';
import { MAX_UINT256, MessageType, roundNumber, Web3Units } from 'helpers';
import {
  StakingPoolItemBondedTokensData,
  StakingPoolItemData,
  TokenData,
  TokenSymbol,
} from 'models';
import React, { useEffect, useReducer } from 'react';
import { Accordion, Card, Nav, Spinner, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBalance, selectBalance, selectLPBalance, selectWeb3 } from 'store';
import Web3 from 'web3';

import {
  initialStakingPoolDetailsState,
  StakingPoolDetailsActions,
  stakingPoolDetailsReducer,
} from '../store/reducer';
import { StakingPoolItemDetails } from './StakingPoolItemDetails';

interface StakingPoolItemProps {
  stakingPoolItemData: StakingPoolItemData;
  tokenData: TokenData;
}

export const StakingPoolItem: React.FC<StakingPoolItemProps> = ({
  stakingPoolItemData,
  tokenData,
}) => {
  const dispatchApp = useDispatch();
  const web3: Web3 = useSelector(selectWeb3);
  const balance: string = useSelector(selectBalance);
  const LPBalance: string = useSelector(selectLPBalance);
  const [state, dispatch] = useReducer(
    stakingPoolDetailsReducer,
    initialStakingPoolDetailsState
  );

  console.log(state.stakingValue);

  useEffect(() => {
    if (web3 && stakingPoolItemData.stakingPoolItemContract) {
      getStakingPoolDetailsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    balance,
    LPBalance,
    stakingPoolItemData.stakingPoolItemContract,
    state.loading,
  ]);

  // TODO: Get from one contract method
  const getStakingPoolDetailsData = async () => {
    console.log('Getting staking data');
    try {
      const accounts = await web3.eth.getAccounts();
      // Allowance
      console.log(
        accounts[0],
        stakingPoolItemData.stakingPoolItemContract.options.address
      );
      const stakingAllowanceWei = await tokenData.tokenContract.methods
        .allowance(
          accounts[0],
          stakingPoolItemData.stakingPoolItemContract.options.address
        )
        .call();
      const stakingAllowance = web3.utils.fromWei(
        stakingAllowanceWei,
        Web3Units.Ether
      );

      // Staked
      const stakedTokensWei = await stakingPoolItemData.stakingPoolItemContract.methods
        .balanceOf(accounts[0])
        .call();
      const stakedTokens = web3.utils.fromWei(stakedTokensWei, Web3Units.Ether);

      // Earned
      const rewardTokensWei = await stakingPoolItemData.stakingPoolItemContract.methods
        .earned(accounts[0])
        .call();
      const rewardTokens = web3.utils.fromWei(rewardTokensWei, Web3Units.Ether);

      // TVL
      const totalValueLockedWei = await stakingPoolItemData.stakingPoolItemContract.methods
        .totalSupply()
        .call();
      const totalValueLocked = web3.utils.fromWei(
        totalValueLockedWei,
        Web3Units.Ether
      );

      // Available For Withdrawal
      const availableForWithdrawalWei = await stakingPoolItemData.stakingPoolItemContract.methods
        .availableForWithdrawal(accounts[0])
        .call();
      const availableForWithdrawal = web3.utils.fromWei(
        availableForWithdrawalWei,
        Web3Units.Ether
      );

      let bondedTokensData: StakingPoolItemBondedTokensData[] = [];
      let totalInUnbonding: string;
      if (stakingPoolItemData.bondingPeriod) {
        bondedTokensData = await stakingPoolItemData.stakingPoolItemContract.methods
          .getUnbondings(accounts[0])
          .call();
        const unbondingPeriod = await stakingPoolItemData.stakingPoolItemContract.methods
          .unbondingPeriod()
          .call();

        bondedTokensData = bondedTokensData.map(
          ({ amount, timestamp }: StakingPoolItemBondedTokensData) => ({
            amount: web3.utils.fromWei(amount, Web3Units.Ether),
            timestamp: (+timestamp + +unbondingPeriod).toString(),
          })
        );

        const totalInUnbondingWei = await stakingPoolItemData.stakingPoolItemContract.methods
          .totalInUnbonding(accounts[0])
          .call();
        totalInUnbonding = web3.utils.fromWei(
          totalInUnbondingWei,
          Web3Units.Ether
        );
      }

      // TODO: Display Fee info
      const unstakingFee = await stakingPoolItemData.stakingPoolItemContract.methods
        .unstakingFee()
        .call();

      // TODO: Display and use APR
      // // Reward Rate
      // const rewardRateWei = await stakingPoolItemData.stakingPoolItemContract.methods
      //   .rewardRate()
      //   .call();
      // console.log('reward rate wei', rewardRateWei);
      // const apr: BN = getApr(web3, rewardRateWei, totalValueLockedWei);
      // console.log(apr.toString());
      console.log({
        stakingAllowance,
        rewardTokens,
        stakedTokens,
        totalValueLocked,
        availableForWithdrawal,
        fee: 0,
        bondedTokensData,
        totalInUnbonding,
      });
      dispatch({
        type: StakingPoolDetailsActions.SetStaking,
        payload: {
          stakingAllowance,
          rewardTokens,
          stakedTokens,
          totalValueLocked,
          availableForWithdrawal,
          fee: 0,
          bondedTokensData,
          totalInUnbonding,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onApprove = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      const accounts = await web3.eth.getAccounts();
      console.log(state.staking.stakingAllowance);

      await tokenData.tokenContract.methods
        .approve(
          stakingPoolItemData.stakingPoolItemContract.options.address,
          MAX_UINT256
        )
        .send({ from: accounts[0] });

      // TODO: Find better way for refresh
      dispatch({
        type: StakingPoolDetailsActions.SetStakingValue,
        payload: '',
      });
    } catch (err) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    }
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });

    dispatchApp(getBalance(web3, tokenData.tokenContract, tokenData.symbol));
  };

  const onStake = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      const accounts = await web3.eth.getAccounts();
      const stakingValueWei = web3.utils.toWei(
        state.stakingValue.toString(),
        Web3Units.Ether
      );

      await stakingPoolItemData.stakingPoolItemContract.methods
        .stake(stakingValueWei)
        .send({
          from: accounts[0],
        });
    } catch (err) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    }

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
    dispatch({ type: StakingPoolDetailsActions.SetStakingValue, payload: '' });

    dispatchApp(getBalance(web3, tokenData.tokenContract, tokenData.symbol));
  };

  const onUnstake = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      const accounts = await web3.eth.getAccounts();
      const unstakingValueWei = web3.utils.toWei(
        state.unstakingValue.toString(),
        Web3Units.Ether
      );
      await stakingPoolItemData.stakingPoolItemContract.methods
        .unstake(unstakingValueWei)
        .send({
          from: accounts[0],
        });
    } catch (err) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    }

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
    dispatch({
      type: StakingPoolDetailsActions.SetUnstakingValue,
      payload: '',
    });

    dispatchApp(getBalance(web3, tokenData.tokenContract, tokenData.symbol));
  };

  const onWithdraw = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    instant?: boolean
  ) => {
    event.preventDefault();
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      const accounts = await web3.eth.getAccounts();

      if (instant) {
        // TODO: Available for withdrawal + others = totalInUnbonding?
        const instantWithdrawValue = web3.utils
          .toBN(state.staking.totalInUnbonding)
          .add(web3.utils.toBN(state.staking.availableForWithdrawal));
        const instantWithdrawValueWei = web3.utils.toWei(instantWithdrawValue);

        await stakingPoolItemData.stakingPoolItemContract.methods
          .withdrawInstant(instantWithdrawValueWei)
          .send({
            from: accounts[0],
          });
      } else {
        const withdrawValue = web3.utils.toWei(
          state.staking.availableForWithdrawal,
          Web3Units.Ether
        );

        console.log('Withdraw value', withdrawValue);

        await stakingPoolItemData.stakingPoolItemContract.methods
          .withdraw(withdrawValue)
          .send({
            from: accounts[0],
          });
      }
    } catch (err) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    }

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
    dispatch({
      type: StakingPoolDetailsActions.SetUnstakingValue,
      payload: '',
    });

    dispatchApp(getBalance(web3, tokenData.tokenContract, tokenData.symbol));
  };

  const onClaim = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      const accounts = await web3.eth.getAccounts();
      await stakingPoolItemData.stakingPoolItemContract.methods
        .getReward()
        .send({
          from: accounts[0],
        });
    } catch (err) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    }

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });

    dispatchApp(getBalance(web3, tokenData.tokenContract, tokenData.symbol));
  };

  const onSelectTab = () => {
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });

    // TODO: Fix - fired also on setLoading
    getStakingPoolDetailsData();
  };

  const onStakeValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch({
      type: StakingPoolDetailsActions.SetStakingValue,
      payload: event.target.value,
    });
  };

  const onUnstakeValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch({
      type: StakingPoolDetailsActions.SetUnstakingValue,
      payload: event.target.value,
    });
  };

  const onApplyPercentage = (
    percentage: number,
    value: string,
    type: string
  ) => {
    const weiValue = web3.utils.toBN(web3.utils.toWei(value, Web3Units.Ether));
    const processedWeiValue = weiValue
      .mul(web3.utils.toBN(percentage))
      .div(web3.utils.toBN(100));
    const roundedNumber = web3.utils.fromWei(
      processedWeiValue,
      Web3Units.Ether
    );

    if (type === 'stake') {
      dispatch({
        type: StakingPoolDetailsActions.SetStakingValue,
        payload: roundedNumber,
      });
    } else {
      dispatch({
        type: StakingPoolDetailsActions.SetUnstakingValue,
        payload: roundedNumber,
      });
    }
  };

  const renderAccordionHeader = () => {
    return (
      <div className="staking-pool-item-header">
        {state.staking && (
          <>
            <div className="name">
              <i
                className={`accordion-icon fas fa-chevron-down ${
                  state.isExpanded ? 'rotated' : ''
                }`}
              ></i>
              {stakingPoolItemData.poolItemName}
            </div>
            <div className="apy">75.91%</div>
            <div className="total">
              TVL: {roundNumber(state.staking.totalValueLocked)}{' '}
              {tokenData.symbol}
            </div>
          </>
        )}
        {!state.staking && (
          <div className="spinner-wrapper">
            <Spinner
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </div>
        )}
      </div>
    );
  };

  const stakingPoolItemClassName = `staking-pool-item ${stakingPoolItemData?.poolItemtype
    .toLocaleLowerCase()
    .replaceAll(' ', '-')}`;

  return (
    <Accordion className={stakingPoolItemClassName}>
      <Card className={!state.staking && 'loading'}>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="0"
          onClick={() => {
            state.staking &&
              dispatch({
                type: StakingPoolDetailsActions.SetIsExpanded,
                payload: !state.isExpanded,
              });
          }}
        >
          {renderAccordionHeader()}
        </Accordion.Toggle>

        {state.staking && (
          <Accordion.Collapse
            eventKey="0"
            unmountOnExit={true}
            mountOnEnter={true}
          >
            <Card.Body>
              <div className="staking-pool-item-body">
                <StakingPoolItemDetails
                  balance={
                    tokenData.symbol === TokenSymbol.PNY ? balance : LPBalance
                  }
                  staking={state.staking}
                  symbol={tokenData.symbol}
                />
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="stake"
                  onSelect={onSelectTab}
                >
                  <Nav variant="pills" className="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="stake">Stake</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="withdraw">Withdraw</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="claim">Claim</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="tabs">
                    <StakingPoolItemStakeTabContent
                      loading={state.loading}
                      balance={balance}
                      stakingValue={state.stakingValue}
                      staking={state.staking}
                      stakingPoolItemData={stakingPoolItemData}
                      onStake={event => onStake(event)}
                      onApprove={event => onApprove(event)}
                      onStakeValueChange={event => onStakeValueChange(event)}
                      onApplyPercentage={percentage =>
                        onApplyPercentage(percentage, balance, 'stake')
                      }
                    />
                    <StakingPoolItemWithdrawTabContent
                      loading={state.loading}
                      lockPeriod={stakingPoolItemData.lockPeriod}
                      bondingPeriod={stakingPoolItemData.bondingPeriod}
                      unstakingValue={state.unstakingValue}
                      staking={state.staking}
                      tokenData={tokenData}
                      stakingPoolItemData={stakingPoolItemData}
                      onWithdraw={(event, instant) =>
                        onWithdraw(event, instant)
                      }
                      onUnstake={event => onUnstake(event)}
                      onUnstakeValueChange={event =>
                        onUnstakeValueChange(event)
                      }
                      onApplyPercentage={percentage =>
                        onApplyPercentage(
                          percentage,
                          state.staking.stakedTokens,
                          'unstake'
                        )
                      }
                    />
                    <StakingPoolItemClaimTabContent
                      loading={state.loading}
                      staking={state.staking}
                      onClaim={event => onClaim(event)}
                      tokenData={tokenData}
                    />
                    {state.errorMessage && (
                      <Message
                        descriptionText={state.errorMessage}
                        messageType={MessageType.Error}
                      />
                    )}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        )}
      </Card>
    </Accordion>
  );
};
