import BN from 'bn.js';
import Web3 from 'web3';

export const MAX_UINT256 =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

// TODO: Problem with calculations, fix - further MR
// apr = yieldPerSec * 3600 * 24 * 365 * 100
export const getApr = (
  web3: Web3,
  rewardRateWei: number,
  totalValueLockedWei: number
): BN => {
  const rewardRateBN = web3.utils.toBN(rewardRateWei);
  const totalValueLockedBN = web3.utils.toBN(totalValueLockedWei);
  const yieldPerSec = rewardRateBN.div(totalValueLockedBN);
  const multiplier = web3.utils
    .toBN(24)
    .mul(web3.utils.toBN(365).mul(web3.utils.toBN(100)));

  return yieldPerSec.mul(web3.utils.toBN(3600).mul(multiplier));
};

// TODO: Problem with calculations, fix - further MR
// apy = (1 + yieldPerSec * 3600 * 24) ** 365 * 100
export const getApyCompoundDaily = (
  web3: Web3,
  rewardRateWei: number,
  totalValueLockedWei: number
): BN => {
  const rewardRateBN: BN = web3.utils.toBN(rewardRateWei);
  const totalValueLockedBN: BN = web3.utils.toBN(totalValueLockedWei);
  const yieldPerSec = rewardRateBN.div(totalValueLockedBN);
  const multiplier1 = web3.utils.toBN(3600).mul(web3.utils.toBN(24));
  const multiplier2 = web3.utils.toBN(365).mul(web3.utils.toBN(100));

  return yieldPerSec
    .mul(multiplier1)
    .addn(1)
    .pow(multiplier2);
};

// TODO: Problem with calculations, fix - further MR
// apy = (1 + yieldPerSec * 3600) ** (24 * 365) * 100
export const getApyCompoundHourly = (
  web3: Web3,
  rewardRateWei: number,
  totalValueLockedWei: number
): BN => {
  const rewardRateBN: BN = web3.utils.toBN(rewardRateWei);
  const totalValueLockedBN: BN = web3.utils.toBN(totalValueLockedWei);
  const yieldPerSec = rewardRateBN.div(totalValueLockedBN);
  const multiplier1 = web3.utils.toBN(3600);
  const multiplier2 = web3.utils.toBN(24).mul(web3.utils.toBN(365));
  const multiplier3 = web3.utils.toBN(100);

  return yieldPerSec
    .mul(multiplier1)
    .addn(1)
    .pow(multiplier2)
    .mul(multiplier3);
};
