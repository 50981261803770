import { ButtonWithSpinner, DataItem, Message } from 'components';
import {
  MessageText,
  MessageType,
  roundNumber,
  VestingPoolType,
  Web3Units,
} from 'helpers';
import { TokenData, TokenSymbol } from 'models';
import React, { useEffect, useReducer } from 'react';
import { Card, ProgressBar, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBalance, selectBalance, selectWeb3 } from 'store';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

import {
  initialVestingPoolState,
  VestingPoolActions,
  vestingPoolReducer,
} from './store/reducer';

interface VestingPoolProps {
  tokenData: TokenData;
  vestingContract: Contract;
  poolType: VestingPoolType;
}

const VestingPool: React.FC<VestingPoolProps> = ({
  tokenData,
  vestingContract,
  poolType,
}) => {
  const dispatchApp = useDispatch();
  const web3: Web3 = useSelector(selectWeb3);
  const balance: string = useSelector(selectBalance);
  const [state, dispatch] = useReducer(
    vestingPoolReducer,
    initialVestingPoolState
  );

  useEffect(() => {
    if (web3 && vestingContract) {
      getVestingPoolDetailsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance, vestingContract, state.loading]);

  const getVestingPoolDetailsData = async () => {
    try {
      const accounts = await web3.eth.getAccounts();

      // Allocated
      const amountAllocatedWei = await vestingContract.methods
        .amountAllocated(accounts[0])
        .call();
      const amountAllocated = web3.utils.fromWei(
        amountAllocatedWei,
        Web3Units.Ether
      );
      // Claimable
      const amountClaimableWei = await vestingContract.methods
        .amountClaimable(accounts[0])
        .call();
      const amountClaimable = web3.utils.fromWei(
        amountClaimableWei,
        Web3Units.Ether
      );
      // Left
      const amountLeftWei = await vestingContract.methods
        .amountLeft(accounts[0])
        .call();
      const amountLeft = web3.utils.fromWei(amountLeftWei, Web3Units.Ether);

      dispatch({
        type: VestingPoolActions.SetVesting,
        payload: { amountAllocated, amountLeft, amountClaimable },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const progress = +state.vesting?.amountLeft
    ? (+state.vesting?.amountClaimable / +state.vesting?.amountAllocated) * 100
    : 100;

  const onClaim = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    dispatch({ type: VestingPoolActions.SetLoading, payload: true });
    dispatch({ type: VestingPoolActions.SetErrorMessage, payload: '' });

    try {
      const accounts = await web3.eth.getAccounts();
      await vestingContract.methods.withdrawVested().send({
        from: accounts[0],
      });
    } catch (err) {
      dispatch({
        type: VestingPoolActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    }

    dispatch({ type: VestingPoolActions.SetLoading, payload: false });
    dispatchApp(getBalance(web3, tokenData.tokenContract, tokenData.symbol));
  };

  return (
    <Card body className="vesting-pool">
      {state.vesting && (
        <>
          <div className="vesting-pool-name">{poolType}</div>
          <div className="vesting-pool-details">
            <DataItem label="Total Allocated">
              {roundNumber(state.vesting.amountAllocated)} {TokenSymbol.PNY}
            </DataItem>
            <DataItem label="Claimable / Left">
              {+state.vesting.amountLeft ? (
                <>
                  {roundNumber(state.vesting.amountClaimable)} /{' '}
                  {roundNumber(state.vesting.amountLeft)} {TokenSymbol.PNY}
                </>
              ) : (
                <span>Everything claimed</span>
              )}
            </DataItem>
            {+state.vesting.amountLeft && (
              <ButtonWithSpinner
                text="Claim"
                classes="btn btn-style-three vesting-pool-button"
                onClick={onClaim}
                disabled={!+state.vesting.amountClaimable || state.loading}
                loading={state.loading}
              />
            )}
          </div>
          <div className="vesting-pool-progress">
            <ProgressBar
              animated
              striped
              variant="info"
              now={progress}
              // label={`${progress}%`}
            />
          </div>
          {!+state.vesting.amountClaimable ||
            (!+state.vesting.amountLeft && (
              <Message
                messageType={MessageType.Primary}
                descriptionText={MessageText.NothingToClaim}
              />
            ))}
        </>
      )}
      {!state.vesting && (
        <div className="spinner-wrapper">
          <Spinner animation="border" role="status" variant="light" />
        </div>
      )}
    </Card>
  );
};

export default VestingPool;
