/* eslint-disable @typescript-eslint/no-unused-vars */

import tokenLPContractJSON from 'contracts/LPToken.json';
import stakingLPTwelveMonthsContractJSON from 'contracts/StakingLP12Months.json';
import stakingLPThreeMonthsContractJSON from 'contracts/StakingLP3Months.json';
import stakingLPSixMonthsContractJSON from 'contracts/StakingLP6Months.json';
import stakingLPLiquidContractJSON from 'contracts/StakingLPLiquid.json';
import stakingSATwelveMonthsContractJSON from 'contracts/StakingSA12Months.json';
import stakingSAThreeMonthsContractJSON from 'contracts/StakingSA3Months.json';
import stakingSASixMonthsContractJSON from 'contracts/StakingSA6Months.json';
import stakingSALiquidContractJSON from 'contracts/StakingSALiquid.json';
import tokenContractJSON from 'contracts/Token.json';
import privateVestingContractJSON from 'contracts/VestingPrivate.json';
import publicVestingContractJSON from 'contracts/VestingPublic.json';
import seedVestingContractJSON from 'contracts/VestingSeed.json';
import teamVestingContractJSON from 'contracts/VestingTeam.json';
import {
  PlaynityConfig,
  PlaynityTokenName,
  StakingPoolConfig,
  StakingPoolItemName,
  StakingPoolItemType,
  StakingPoolType,
  TokenSymbol,
  VestingPoolConfig,
  VestingPoolType,
} from 'models';

export enum PermittedNetwork {
  BinanceSmartChain = '56',
  BinanceSmartChainTestnet = '97',
}

const LPStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.LP,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.Liquid,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingLPLiquidContractJSON,
      bondingPeriod: 5,
      unbondingFee: '5%',
    },
    {
      poolItemName: StakingPoolItemName.ThreeMonths,
      poolItemtype: StakingPoolItemType.Locked,
      stakingContractJSON: stakingLPThreeMonthsContractJSON,
      lockPeriod: 3,
    },
    // {
    //   poolItemName: StakingPoolItemName.SixMonths,
    //   poolItemtype: StakingPoolItemType.Locked,
    //   stakingContractJSON: stakingLPSixMonthsContractJSON,
    //   lockPeriod: 6,
    // },
    // {
    //   poolItemName: StakingPoolItemName.TwelveMonths,
    //   poolItemtype: StakingPoolItemType.Locked,
    //   stakingContractJSON: stakingLPTwelveMonthsContractJSON,
    //   lockPeriod: 12,
    // },
  ],
};

const SAStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.SA,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.Liquid,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSALiquidContractJSON,
      bondingPeriod: 5,
      unbondingFee: '5%',
    },
    {
      poolItemName: StakingPoolItemName.ThreeMonths,
      poolItemtype: StakingPoolItemType.Locked,
      stakingContractJSON: stakingSAThreeMonthsContractJSON,
      lockPeriod: 3,
    },
    {
      poolItemName: StakingPoolItemName.ThreeMonths,
      poolItemtype: StakingPoolItemType.Locked,
      stakingContractJSON: stakingSASixMonthsContractJSON,
      lockPeriod: 6,
    },
    {
      poolItemName: StakingPoolItemName.TwelveMonths,
      poolItemtype: StakingPoolItemType.Locked,
      stakingContractJSON: stakingSATwelveMonthsContractJSON,
      lockPeriod: 12,
    },
  ],
};

const activeStakingPools: StakingPoolConfig[] = [
  LPStakingPool,
  // SAStakingPool
];

const activeVestingPools: VestingPoolConfig[] = [
  {
    poolType: VestingPoolType.Team,
    vestingContractJSON: teamVestingContractJSON,
  },
  {
    poolType: VestingPoolType.Seed,
    vestingContractJSON: seedVestingContractJSON,
  },
  {
    poolType: VestingPoolType.Private,
    vestingContractJSON: privateVestingContractJSON,
  },
  {
    poolType: VestingPoolType.Public,
    vestingContractJSON: publicVestingContractJSON,
  },
];

export const playnityConfig: PlaynityConfig = {
  tokens: [
    {
      name: PlaynityTokenName.Playnity,
      symbol: TokenSymbol.PNY,
      decimals: 18,
      tokenContractJSON: tokenContractJSON,
    },
    {
      name: PlaynityTokenName.LPPlaynity,
      symbol: TokenSymbol.LPPNY,
      decimals: 18,
      tokenContractJSON: tokenLPContractJSON,
    },
  ],
  vesting: {
    pools: activeVestingPools,
  },
  staking: {
    pools: activeStakingPools,
  },
};
