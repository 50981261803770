import Web3 from 'web3';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface AppState {
  web3: Web3;
  connectedAddress: string;
  initializingFinished: boolean;
  balance: string;
  balanceLP: string;
  mainError: boolean;
}

export const initialAppState: AppState = {
  web3: null,
  connectedAddress: null,
  balance: null,
  balanceLP: null,
  initializingFinished: null,
  mainError: null,
};

export const appSlice = createSlice<
  AppState,
  SliceCaseReducers<AppState>,
  string
>({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    setWeb3: (state, action: PayloadAction<Web3>) => {
      state.web3 = action.payload;
    },
    setConnectedAddress: (state, action: PayloadAction<string>) => {
      state.connectedAddress = action.payload;
    },
    setBalance: (state, action: PayloadAction<string>) => {
      state.balance = action.payload;
    },
    setBalanceLP: (state, action: PayloadAction<string>) => {
      state.balanceLP = action.payload;
    },
    setInitializingFinished: (state, action: PayloadAction<boolean>) => {
      state.initializingFinished = action.payload;
    },
    setInitialData: (state, action: PayloadAction<AppState>) => {
      state.web3 = action.payload.web3;
      state.connectedAddress = action.payload.connectedAddress;
    },
    setMainError: (state, action: PayloadAction<boolean>) => {
      state.mainError = action.payload;
    },
  },
});
