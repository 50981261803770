import { VestingPoolData } from 'models';
import { State } from 'store';
import { VestingState } from 'store/reducers';

import { createSelector } from '@reduxjs/toolkit';

const selectVestingState = (state: State): VestingState => state.vesting;

export const selectVestingPools = createSelector(
  [selectVestingState],
  (vestingState: VestingState): VestingPoolData[] => vestingState.pools
);
