import React, { PropsWithChildren } from 'react';
import { Spinner } from 'react-bootstrap';

interface ButtonWithSpinnerProps {
  classes: string;
  loading: boolean;
  text?: string;
  disabled?: boolean;
  spinnerVariant?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const ButtonWithSpinner: React.FC<PropsWithChildren<
  ButtonWithSpinnerProps
>> = ({
  children,
  classes,
  loading,
  disabled,
  text,
  spinnerVariant,
  onClick,
}) => {
  // TODO: Fix jumping button when showing loader
  return (
    <button
      className={`btn ${classes}`}
      onClick={event => onClick(event)}
      disabled={disabled || loading}
    >
      {loading ? (
        <Spinner
          animation="border"
          role="status"
          variant={spinnerVariant}
          size="sm"
        />
      ) : (
        text || children
      )}
    </button>
  );
};
