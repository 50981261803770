export enum StakingPoolItemType {
  Locked = 'Locked',
  Bonded = 'Bonded',
}
import { Contract } from 'web3-eth-contract';

export enum StakingPoolType {
  LP = 'LP Staking',
  SA = 'Single Asset Staking',
}

export enum StakingPoolItemName {
  Liquid = 'Liquid',
  ThreeMonths = '3 Months Lock',
  SixMonths = '6 Months Lock',
  TwelveMonths = '12 Months Lock',
}

export interface StakingPoolItemBondedTokensData {
  amount: string;
  timestamp: string;
}

export interface StakingPoolItemStakingData {
  stakingAllowance: string;
  rewardTokens: string;
  stakedTokens: string;
  totalValueLocked: string;
  availableForWithdrawal: string;
  fee: string;
  bondedTokensData: StakingPoolItemBondedTokensData[];
  totalInUnbonding: string;
}

export interface StakingConfig {
  pools: StakingPoolConfig[];
}

export type StakingPoolItemLockPeriod = 3 | 6 | 12;

export interface StakingPoolItemConfig {
  poolItemName: StakingPoolItemName;
  poolItemtype: StakingPoolItemType;
  stakingContractJSON: any;
  unbondingFee?: string;
  bondingPeriod?: number;
  lockPeriod?: StakingPoolItemLockPeriod;
}

export interface StakingPoolItemData {
  poolItemName: StakingPoolItemName;
  poolItemtype: StakingPoolItemType;
  stakingPoolItemContract: Contract;
  unbondingFee?: string;
  bondingPeriod?: number;
  lockPeriod?: 3 | 6 | 12;
}

export interface StakingPoolConfig {
  poolType: StakingPoolType;
  poolItems: StakingPoolItemConfig[];
}

export interface StakingPoolData {
  poolType: StakingPoolType;
  poolItems: StakingPoolItemData[];
}
