import { StakingPoolData, StakingPoolType, TokenData } from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectLPTokenData, selectStakingPools, selectTokenData } from 'store';

import StakingPool from './StakingPool';

const StakingPools: React.FC = () => {
  const tokenData: TokenData = useSelector(selectTokenData);
  const LPTokenData: TokenData = useSelector(selectLPTokenData);
  const stakingPoolsData: StakingPoolData[] = useSelector(selectStakingPools);
  const stakingPools = stakingPoolsData?.map(
    (stakingPoolData: StakingPoolData, index: number) => {
      return (
        <StakingPool
          key={`staking-pool-compoonent-${index}`}
          stakingPoolData={stakingPoolData}
          tokenData={
            stakingPoolData.poolType === StakingPoolType.LP
              ? LPTokenData
              : tokenData
          }
        />
      );
    }
  );

  return (
    <div className="staking-pools">
      {tokenData && LPTokenData && stakingPools && <>{stakingPools}</>}
    </div>
  );
};

export default StakingPools;
