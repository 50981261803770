import { SectionDetails } from 'components';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectVestingPools } from 'store';

import VestingPools from './VestingPools';

export const Vesting: React.FC = () => {
  const vestingPools = useSelector(selectVestingPools);
  const description = 'Check your vested tokens and claim if available.';
  const title = 'Vesting';

  return (
    <div className="vesting">
      <SectionDetails description={description} title={title} />
      {vestingPools && <VestingPools />}
    </div>
  );
};
