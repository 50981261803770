import { Contract } from 'web3-eth-contract';

export enum VestingPoolType {
  Team = 'Team/Advisors',
  Seed = 'Seed presale',
  Private = 'Private presale',
  Public = 'Public presale',
}

export interface VestingPoolConfig {
  poolType: VestingPoolType;
  vestingContractJSON: any;
}

export interface VestingConfig {
  pools: VestingPoolConfig[];
}

export interface VestingPoolData {
  poolType: VestingPoolType;
  vestingPoolContract: Contract;
}
