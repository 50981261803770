import Web3 from 'web3';

export enum Web3Units {
  Ether = 'ether',
}

export const getWeb3 = async (): Promise<Web3> => {
  let web3: Web3;

  if (window.ethereum) {
    web3 = new Web3(window.ethereum);
    // Request account access if needed
    await window.ethereum.enable();
    // Accounts now exposed
  } else if (window.web3) {
    // Legacy dapp browsers...
    // Use Mist/MetaMask's provider.
    web3 = window.web3;
    console.log('Injected web3 detected.');
  } else {
    // Fallback to localhost; use dev console port by default...
    const provider = new Web3.providers.HttpProvider('http://127.0.0.1:8545');
    web3 = new Web3(provider);
    console.log('No web3 instance injected, using Local web3.');
  }

  return web3;
};
